/* Dashboard */
.dashboard-vector-map {
    width: 100%;
    height: 300px;
}
.proBanner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    .purchase-popup {
      @extend .grid-margin;
      background: #000;
      color: #fff;
      padding: 15px 20px;
      @include border-radius(3px);
  
      .btn {
        margin-right: 20px;
        font-weight: 500;
        color: $white;
        @include border-radius(5px);
        @include transition-duration(0.2s);
  
        &.download-button {
          background: rgba(249, 249, 249, 0.7);
          color: #969292;
          border: 1px solid darken(#e4e4e4,5%);
        }
  
        &.purchase-button {
          background-color: theme-color(info);
          color: $white;
          border: none;
          line-height: 1;
          vertical-align: middle;
        }
      }
  
      p {
        margin-bottom: auto;
        margin-top: auto;
        color: darken(#e4e4e4,20%);
        font-weight: 400;
        vertical-align: middle;
        line-height: 1;
      }
  
      i {
        vertical-align: middle;
        line-height: 1;
        margin: auto 0;
        color: darken(#e4e4e4,20%);
      }
      .bannerClose {
        cursor: pointer;
      }
    }
    &.hide {
      display: none;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2e1c5e;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2e1c5e;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .gradientIg{
    margin-top:20px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: linear-gradient(to right, red, orange);
    padding: 5px;
  }
  
  .imageIg{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: white;
  }